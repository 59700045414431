<template>
  <div>
    <div class="row mt-3">
      <h3 class="col-sm-12">Comentarios</h3>
    </div>

    <div v-if="(comentarios_paginados.length > 0)" class="comentarios">
      <div v-for="(comentario, index) in comentarios_paginados" :key="index" class="comentario mb-2">
        <div class="texto">{{ comentario.comentario }}</div>
        <div class="info">
          <div class="nombre">{{ comentario.nombre }}</div>
          <div class="fecha">{{ $moment(comentario.created_at).format('ddd DD MMM YYYY HH:mm') }}</div>
        </div>
      </div>
      <div v-if="(total_paginas.length > 1)" class="row">
        <div class="col-sm-5 offset-sm-7 text-right paginacion">
          <div class="anterior" @click="primera_pagina"><i class="fa-solid fa-angles-left"></i></div>
          <div class="anterior" @click="pagina_anterior"><i class="fa-solid fa-chevron-left"></i></div>
          <div v-for="pagina in total_paginas" :class="(pagina_actual == pagina) ? 'pagina activo' : 'pagina'" @click="pagina_actual=pagina">{{ pagina }}</div>
          <div class="siguiente" @click="pagina_siguiente"><i class="fa-solid fa-chevron-right"></i></div>
          <div class="siguiente" @click="ultima_pag"><i class="fa-solid fa-angles-right"></i></div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-sm-12"><div class="form-control">Sin comentarios</div></div>
    </div>

    <form v-if="(comprobante.estatus == 1)" @submit.prevent="agregar_comentario">
      <div class="row mt-3">
        <div class="col-sm-12">
          <div class="input-group mb-3">
            <input v-model="comentario" type="text" class="form-control" id="comentario" name="comentario" placeholder="Comentario...">
            <div class="input-group-append">
              <button class="btn btn-primary">Agregar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/apps/tesoreria/api/viaticos'
import apiConstelacion from '@/apps/constelacion/api/tesoreria'

export default {
  props: {
    origen: {
      type: String
      ,default: 'tesoreria'
    },
    viatico: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
    comprobante: {
      type: Object,
      default: function () {
        return {
          estatus: null,
          comentarios: []
        }
      }
    }
  },
  data() {
    return {
      pagina_actual: 1,
      por_pagina: 3,
      a_mostrar: 3,
      modal_rechazar: false,
      comentario: null
    }
  }
  ,methods: {
    async agregar_comentario() {
      try {
        if (this.comentario == '')
          return this.$helper.showMessage('Error','Debes definir el cuerpo del mensaje','error', 'alert');

        let res;
        this.$log.info('origen ', this.origen);
        this.$log.info('apiConstelacion ', apiConstelacion);
        if (this.origien == 'tesoreria')
          res = (await api.agregar_comentario_concepto(this.viatico.id, this.comprobante.id, {comentario: this.comentario})).data;
        else
          res = (await apiConstelacion.agregar_comentario_concepto(this.viatico.id, this.comprobante.id, {comentario: this.comentario})).data;
          
        this.$log.info('res', res);

        this.$emit('update');
        this.comentario = null;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    primera_pagina() {

    }
    ,pagina_anterior() {

    }
    ,pagina_siguiente() {

    }
    ,ultima_pag() {

    }
  }
  ,computed: {
    comentarios_paginados() {
      return this.$paginator.paginate_data(this.comprobante.comentarios, this.pagina_actual, this.por_pagina, 'desc');
    }
    ,total_paginas() {
      return this.$paginator.paginate_navigation(this.comprobante.comentarios, this.pagina_actual, this.por_pagina, 3);
    }
  }
}
</script>

<style lang="scss" scoped>
.comentarios {
  .comentario {
    border: solid 1px #e0e0e0;
    border-radius: 4px;

    .texto {
      padding: 5px 10px;
    }

    .info {
      background-color: #e0e0e0;
      padding: 5px 10px;
      font-size: 0.8em;
      overflow: auto;

      .nombre, .fecha {
        display: inline-block;
      }

      .nombre {
        width: 75%;
      }

      .fecha {
        width: 25%;
      }

      .fecha {
        text-align: right;
      }
    }
  }
}

.paginacion {
  margin-bottom: 10px;

  .anterior, .siguiente, .pagina {
    color: #979797;
    display: inline-block;
    padding: 3px 8px;
    cursor: pointer;
  }

  .anterior, .siguiente {
    // min-width: 50px;
    border: solid 1px #e0e0e0;
    border-radius: 3px;

    i {
      font-size: 0.7em;
    }
  }

  .anterior:hover, .siguiente:hover, .pagina:hover {
    color: #493cbe;
  }

  .anterior {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .siguiente {
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .pagina {
    border: solid 1px #e0e0e0;
  }

  .activo {
    background-color: #6159aa;
    color: #fff;
  }
}
</style>